<template>
  <div>
    <nav class="navbar fixed-top navbar-light bg-light">
      <a class="navbar-brand" href="/">
        <img src="images/novartis-sandoz-logo.svg?t=1" height="50" alt="">
      </a>
      <div style="position: absolute; width: 100%;">
        <div class="container">
          <button class="ml-auto mr-3 btn btn-primary reminder-button" v-if="authenticated" @click.prevent="showReminderModal">
            <fa icon="bell" size="2x"></fa>
            <span class="d-md-block d-none ml-2">{{$t('offer.set_reminder')}}</span>
          </button>
          <button class="mr-3 btn btn-primary d-none" ref="loginBtn" @click.prevent="showLoginModal" v-if="!authenticated">
            {{$t('core.login')}}
          </button>
          <button class="mr-3 btn btn-primary" @click.prevent="logout" v-if="authenticated">
            {{$t('core.logout')}}
          </button>
          <router-link tag="button" class="btn btn-primary ml-2 mr-2" v-if="user && (user.is_super_user || user.roles.includes('Sales Rep'))" :to="{name: 'home'}">Admin</router-link>
          <LocaleDropdown class="right-locale mr-2" :right="true" v-if="authenticated"/>
        </div>
      </div>
    </nav>
    <div class="container" id="content">
      <div class="row">
        <div class="col-12 timeline">
          <div class="rb-container mt-5">
            <ul class="rb" v-if="authenticated">
              <li v-for="(item, itemIndex) in offersWithTitle" class="rb-item" :class="{ 'active': isActive(item.offer_start) }"
                  :key="item.id">
                <div class="timestamp">
                  {{ item.offer_start | prettyDate }} - {{ item.offer_end | prettyDate }}
                </div>
                <div v-if="authenticated && item.color" :class="'ribbon ribbon--'+item.color+' d-md-none d-lg-none d-xl-none'">{{ offerSavingText(item) }}<br v-if="offerSavingText(item)">{{
                    item.saving_percent }}</div>

                <div class="item-title">
                  <h1>{{ offerTitle(item) }}</h1>
                  <vue-countdown v-if="isActive(item.offer_start)" :time="getCD(item.offer_end)">
                    <template v-slot="props"><h4>{{$t('offer.available_for', { days: props.days, hours: props.hours })}}</h4></template>
                  </vue-countdown>
                  <vue-countdown v-else :time="getCD(item.offer_start)">
                    <template v-slot="props"><h4>{{$t('offer.available_in', { days: props.days, hours: props.hours
                    })}}</h4></template>
                  </vue-countdown>
                  <img v-if="offerImage(item)" class="offerimg" :src="'/storage/offers/images/'+offerImage(item)" alt="Offer">
                  <div v-if="authenticated && item.color" :class="'ribbon ribbon--'+item.color+' d-none d-md-block'">{{
                      offerSavingText(item) }}<br v-if="offerSavingText(item)">{{
                      item.saving_percent }}</div>
                  <div class="clearfix"></div>
                  <p>
                    {{ offerDescription(item) }}
                  </p>
                  <div v-if="!isActive(item.offer_start)" class="legal_info">
                    {{$t('offer.legal_info')}}
                  </div>
                  <div v-if="isActive(item.offer_start)" class="docs">
                    <template v-if="authenticated">
                      <a v-if="offerDownload(item)"
                         :href="'/storage/offers/downloads/' + offerDownload(item)" target="_blank"
                         class="btn btn-primary">
                        <fa icon="download"></fa>
                        {{$t('offer.download_pdf')}}
                      </a>

                      <a href="#" v-if="item.online_ordering_enabled" @click.prevent="showOfferModal(item, itemIndex)"
                         class="btn btn-primary">
                        <fa icon="shopping-cart"></fa>
                        {{$t('offer.order_now')}}
                      </a>
                    </template>
                    <template v-else>
                      <a v-if="offerDownload(item)" href="#" target="_blank" class="btn btn-primary" @click.prevent="showLoginModal">
                        <fa icon="download"></fa>
                        {{$t('offer.download_pdf')}}
                      </a>

                      <a href="#" v-if="item.online_ordering_enabled" @click.prevent="showLoginModal" class="btn btn-primary">
                        <fa icon="shopping-cart"></fa>
                        {{$t('offer.order_now')}}
                      </a>
                    </template>
                  </div>
                  <div v-else class="docs">
                    <a href="#" target="_blank"
                       class="btn btn-primary disabled">
                      <fa icon="download"></fa>
                      {{$t('offer.download_pdf')}}
                    </a>
                    <a href="#" v-if="item.online_ordering_enabled" target="_blank"
                       class="btn btn-primary disabled">
                      <fa icon="download"></fa>
                      {{$t('offer.order_now')}}
                    </a>
                  </div>
                  <template v-if="authenticated">
                    <div v-if="item.link_kam" class="docs mr-4">
                      <a :href="item.link_kam" target="_blank" class="btn btn-text">
                        <fa icon="link"></fa>
                        KAM</a>
                    </div>
                    <div v-if="item.link_ff" class="docs mr-4">
                      <a :href="item.link_ff" target="_blank" class="btn btn-text">
                        <fa icon="link"></fa>
                        FF</a>
                    </div>
                  </template>
                  <template v-else>
                    <div v-if="item.link_kam" class="docs mr-4">
                      <a :href="item.link_kam" target="_blank" class="btn btn-text" @click.prevent="showLoginModal">
                        <fa icon="link"></fa>
                        KAM</a>
                    </div>
                    <div v-if="item.link_ff" class="docs mr-4">
                      <a :href="item.link_ff" target="_blank" class="btn btn-text" @click.prevent="showLoginModal">
                        <fa icon="link"></fa>
                        FF</a>
                    </div>
                  </template>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <hr v-if="authenticated">
      <div class="row d-flex justify-content-center mb-3" v-if="authenticated">
        <div class="col-10 footer">
          <ul class="footer-links">
            <li class="footer-links-item">
              <router-link to="contact">{{ $t('core.contact') }}</router-link>
            </li>
            <li class="footer-links-item">
              <router-link to="terms_of_use">{{ $t('core.terms_of_use') }}</router-link>
            </li>
            <li class="footer-links-item">
              <router-link to="privacy_policy">{{ $t('core.privacy_policy') }}</router-link>
            </li>
            <li class="footer-links-item">
              <router-link to="about_cookies">{{ $t('core.about_cookies') }}</router-link>
            </li>
            <li class="footer-links-item">
              <a href="#" id="custom-ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</a>
            </li>
          </ul>
        </div>
      </div>
      <b-modal centered :title="$t('offer.order_submitted')" ok-only id="modal-order-submitted" ref="orderSubmittedModal">
        {{$t('offer.order_submitted_text')}}
      </b-modal>
      <b-modal centered size="lg"
              :title="$t('offer.order')"
              :cancel-title="$t('core.cancel')"
              id="modal-order"
              ref="offerModal">
        <o-wizard @page-changed="wizardPageChanged" ref="wizard">
          <o-wizard-step :title="$t('offer.products')">
            <table class="table" v-if="selectedOffer != null">
              <thead>
                <tr>
                  <th>{{$t('offer.pharma_code')}}</th>
                  <th>{{$t('offer.product')}}</th>
                  <th class="text-right">{{$t('offer.efp')}}</th>
                  <th>{{$t('offer.quantity')}}</th>
                  <th class="text-right">{{$t('offer.total')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, i) in selectedOffer.products" :key="i">
                  <td>{{product.pharma_code}}</td>
                  <td>{{product.product}}</td>
                  <td class="text-right">{{product.efp | formatNumber}}</td>
                  <td>
                    <o-input-control v-model.number="product.quantity" type="number" size="small" :min="0" pattern="\d+" title="t" oninput="validity.valid||(value=0)"/>
                  </td>
                  <td class="text-right">{{product.efp * product.quantity | formatNumber}}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr v-if="selectedOfferDiscount > 0">
                  <td colspan="4" class="text-right">
                    {{$t('offer.intermediate_total')}}
                  </td>
                  <td class="text-right">{{selectedOfferProductsTotal | formatNumber}}</td>
                </tr>
                <tr v-if="selectedOfferDiscount > 0">
                  <td colspan="4" class="text-right">
                    {{$t('offer.discount')}} ({{selectedOfferDiscount * 100}}%)
                  </td>
                  <td class="text-right">
                    {{selectedOfferProductsTotal * selectedOfferDiscount | formatNumber}}
                  </td>
                </tr>
                <tr>
                  <td colspan="4" class="text-right">
                    {{$t('offer.total')}}
                  </td>
                  <td class="text-right">
                    {{selectedOfferProductsTotalAfterDiscount | formatNumber}}
                  </td>
                </tr>
              </tfoot>
            </table>
          </o-wizard-step>
          <o-wizard-step :title="$t('offer.login_register')" v-if="showLoginRegisterStep">
            <div v-if="user">
              {{$t('offer.login_successful', {email: user.email})}}
            </div>
            <div v-else>
              <o-oauth-button provider="swissrx" @login-successful="loginSuccessful">
                {{ $t('core.login_with') }} Swiss-Rx
              </o-oauth-button>
              <o-mysandoz-login>
                {{ $t('core.login_with') }} mysandoz.ch
              </o-mysandoz-login>
              <!--
              <div class="vertical-separator"></div>
              <div>login</div>
              -->
            </div>
          </o-wizard-step>
          <o-wizard-step :title="$t('offer.delivery')">

            <b-form-group :label="$t('offer.delivery_type')" v-if="Object.keys(internalWholesaler).length > 0">
              <b-form-radio v-model="deliveryType" name="deliveryType" value="direct">{{$t('offer.direct_delivery')}}</b-form-radio>
              <b-form-radio v-model="deliveryType" name="deliveryType" value="wholesaler">{{$t('offer.wholesaler_delivery')}}</b-form-radio>
            </b-form-group>


            <div v-if="deliveryType == 'wholesaler'">
              <label for="wholesaler">{{$t('offer.wholesaler')}}</label>
              <b-form-select class="mb-3" v-model="wholesaler" text-field="wholesaler" value-field="id" :options="externalWholesalers"></b-form-select>
            </div>

            <b-alert show v-if="logisticsSurchageText" class="mt-2">{{logisticsSurchageText}}</b-alert>

            <o-datepicker :disabled-dates="deliveryDateDisabledDates" :label="$t('offer.delivery_date')" v-model="deliveryDate"/>

          </o-wizard-step>
          <o-wizard-step :title="$t('offer.address')">
            <template v-if="showAddress">
              <o-input :label="$t('offer.gln')" v-model="address.gln"/>
              <o-input :label="$t('offer.name')" v-model="address.name"/>
              <o-input :label="$t('offer.recipient_name')" v-model="address.recipient_name"/>
              <o-input :label="$t('offer.street')" v-model="address.street"/>
              <b-row>
                <b-col>
                  <o-input :label="$t('offer.zip')" v-model="address.zip"/>
                </b-col>
                <b-col>
                  <o-input :label="$t('offer.city')" v-model="address.city"/>
                </b-col>
              </b-row>

              <b-btn variant="secondary" @click.prevent="resetAddress">{{$t('offer.search_new_address')}}</b-btn>
            </template>
            <template v-else>
              <label for="input-live">{{$t('offer.delivery_address')}}</label>
              <b-input-group>
                <b-form-input
                  id="input-live"
                  v-model="addressSearch"
                  :placeholder="$t('offer.type_name_or_address')"
                  trim
                ></b-form-input>
                <b-input-group-append>
                  <b-button variant="primary" @click="searchAddress">{{$t('offer.search')}}</b-button>
                </b-input-group-append>
              </b-input-group>

              <div v-if="'data' in addressSearchResult">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="pl-0">{{$t('offer.name')}}</th>
                      <th>{{$t('offer.street')}}</th>
                      <th>{{$t('offer.zip_city')}}</th>
                      <th class="pr-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="address in addressSearchResult.data" :key="address.id">
                      <td class="pl-0">{{address.descr1}}</td>
                      <td>{{address.street}} {{address.strno}}</td>
                      <td>{{address.zip}} {{address.city}}</td>
                      <td class="pr-0 text-right">
                        <b-btn size="md" @click.prevent="selectAddress(address)">
                          {{$t('offer.select_address')}}
                        </b-btn>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="d-flex">
                  <b-btn @click="loadMore" sm v-if="'next_page_url' in addressSearchResult && addressSearchResult.next_page_url">{{$t('offer.load_more')}}</b-btn>
                  <b-btn class="ml-auto" sm @click.prevent="enterAddressManually">{{$t('offer.enter_address_manually')}}</b-btn>
                </div>
              </div>
            </template>
          </o-wizard-step>
          <o-wizard-step :title="$t('offer.summary')" @page-activated="summaryTabActivated">

            <b-row>
              <b-col>
                <strong>{{$t('offer.address')}}</strong>
                <br>
                {{address.name}}<br>
                <div v-if="address.recipient_name">{{address.recipient_name}}</div>
                {{address.street}}<br>
                {{address.zip}} {{address.city}}<br>
                <br>
                GLN: {{address.gln}}
              </b-col>
              <b-col>
                <strong>{{$t('offer.delivery')}}</strong>
                <br>

                {{selectedDelivery}}
                <br>

                <div v-if="deliveryDate">
                  {{$t('offer.delivery_date')}} {{deliveryDate | formatDate}}
                </div>
              </b-col>
            </b-row>

            <table class="table mt-3" v-if="selectedOffer">
              <thead>
                <tr>
                  <th class="pl-0">{{$t('offer.pharma_code')}}</th>
                  <th>{{$t('offer.product')}}</th>
                  <th>{{$t('offer.efp')}}</th>
                  <th>{{$t('offer.quantity')}}</th>
                  <th class="pr-0 text-right">{{$t('offer.total')}}</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(product, i) in selectedOfferProducts">
                  <tr v-if="product.quantity > 0" :key="i">
                    <td class="pl-0">{{product.pharma_code}}</td>
                    <td>{{product.product}}</td>
                    <td>{{product.efp | formatNumber}}</td>
                    <td>{{product.quantity}}</td>
                    <td class="pr-0 text-right">{{product.efp * product.quantity | formatNumber}}</td>
                  </tr>
                </template>
              </tbody>
              <tfoot>
                <tr v-if="selectedOfferDiscount > 0">
                  <td colspan="4" class="text-right">
                    {{$t('offer.intermediate_total')}}
                  </td>
                  <td class="pr-0 text-right">{{selectedOfferProductsTotal | formatNumber}}</td>
                </tr>
                <tr v-if="selectedOfferDiscount > 0" class="condensed-row">
                  <td colspan="4" class="text-right">
                    {{$t('offer.discount')}} ({{selectedOfferDiscount * 100}}%)
                  </td>
                  <td class="pr-0 text-right">
                    {{selectedOfferProductsTotal * selectedOfferDiscount | formatNumber}}
                  </td>
                </tr>
                <template v-if="selectedOfferProductsTotalLogisticsSurcharge > 0">
                  <tr class="condensed-row">
                    <td colspan="4" class="text-right">
                      {{$t('offer.intermediate_total')}}
                    </td>
                    <td class="pr-0 text-right">
                      {{selectedOfferProductsTotal - selectedOfferProductsTotal * selectedOfferDiscount | formatNumber}}
                    </td>
                  </tr>
                  <tr class="condensed-row">
                    <td colspan="4" class="text-right">
                      {{$t('offer.logistics_surcharge')}}
                    </td>
                    <td class="pr-0 text-right">
                      {{selectedOfferProductsTotalLogisticsSurcharge | formatNumber}}
                    </td>
                  </tr>
                  <tr>
                    <th colspan="4" class="text-right">
                      {{$t('offer.total')}}
                    </th>
                    <th class="pr-0 text-right">
                      {{selectedOfferProductsTotalAfterLogisticsSurcharge | formatNumber}}
                    </th>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <th colspan="4" class="text-right">
                      {{$t('offer.total')}}
                    </th>
                    <th class="pr-0 text-right">
                      {{selectedOfferProductsTotal - selectedOfferProductsTotal * selectedOfferDiscount | formatNumber}}
                    </th>
                  </tr>
                </template>
              </tfoot>
            </table>

            <div>
              <strong>{{$t('offer.signature')}}</strong>
              <span v-if="signature.data" @click="$refs.signature.clearSignature()"> ({{$t('offer.clear')}})</span>
              <span v-else>({{$t('offer.use_mouse_or_touchpad')}})</span>
            </div>
            <o-signature @end-stroke="signatureEndStroke" ref="signature" width="100%" height="200px" v-if="renderSignature" style="border: 1px solid #ced4da;"></o-signature>
            <o-input-control v-model="signature.name" :placeholder="$t('offer.name')" class="mt-1"></o-input-control>

            <div v-if="cartErrors.length > 0">
              <hr>
              <ul class="text-danger">
                <li v-for="(cartError, errIndex) in cartErrors" :key="errIndex">{{cartError}}</li>
              </ul>
            </div>
          </o-wizard-step>
        </o-wizard>
        <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="secondary"
            @click="$refs.offerModal.hide()"
          >
            {{$t('core.cancel')}}
          </b-button>

          <div class="float-right">

            <b-button variant="secondary" v-if="wizardIndex > 0" @click.prevent="previousPage">
              <template>
                {{$t('offer.back')}}
              </template>
            </b-button>

            <b-button variant="primary" @click.prevent="nextPage" v-if="$refs.wizard && !$refs.wizard.isLastPage()">
              <template>
                {{$t('offer.next')}}
              </template>
            </b-button>

            <o-button variant="primary" :disabled="cartErrors.length > 0" @click.prevent="finishOrder" v-if="$refs.wizard && $refs.wizard.isLastPage()" :loading="orderSubmitInProcess">
              <template>
                {{$t('offer.finish')}}
              </template>
            </o-button>
          </div>
        </div>
      </template>

      </b-modal>
      <b-modal centered
              :title="$t('offer.activate_reminder')"
              :ok-title="$t('offer.create')"
              :cancel-title="$t('core.cancel')"
              @ok="reminder"
              id="modal-reminder"
              ref="reminder">
        <o-input :label="$t('core.email')" v-model="form.email"></o-input>
      </b-modal>
      <b-modal centered
              :title="$t('offer.login')"
              ok-only
              :ok-title="$t('offer.close')"
              @ok="reminder"
              id="modal-login"
              ref="login"
              :hide-header-close="true"
              :no-close-on-esc="true"
              :hide-footer="true"
              :no-close-on-backdrop="true">
        <div class="d-flex">
          <div class="text-center">
            <o-oauth-button provider="swissrx" @login-successful="loginSuccessful">
              {{ $t('core.login_with') }} Swiss-Rx
            </o-oauth-button>
            <o-mysandoz-login>
              {{ $t('core.login_with') }} mysandoz.ch
            </o-mysandoz-login>
          </div>
          <!--
          <div class="vertical-separator"></div>
          <div>login</div>
          -->
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import axios from 'axios'
import VueCountdown from '@chenfengyuan/vue-countdown'
import moment from 'moment'
import LocaleDropdown from '~/components/LocaleDropdown'

export default {
  components: {VueCountdown, LocaleDropdown},
  data() {
    return {
      offers: [],
      form: {
        offer_id: null,
        email: ''
      },
      deliveryDate: null,
      renderSignature: false,
      showLoginRegisterStep: true,
      selectedOfferIndex: null,
      selectedOffer: null,
      deliveryType: '',
      wholesalers: [],
      internalWholesaler: {},
      wholesaler: null,
      addressSearch: '',
      addressSearchResult: [],
      showAddress: false,
      deliveryDateDisabledDates: {
        to: new Date()
      },
      address: {
        gln: '',
        name: '',
        street: '',
        zip: '',
        city: '',
        recipient_name: ''
      },
      signature: {
        data: null,
        name: null
      },
      wizardIndex: -1,
      orderSubmitInProcess: false
    }
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout')
      window.location.href = '/'
    },
    wizardPageChanged(selectedIndex) {
      this.wizardIndex = selectedIndex
    },
    summaryTabActivated() {
      // signature pad should only be rendered, if it's visible. Otherwise
      // width and height are wrong. We also have to make sure we don't
      // re-render once it is visible, otherwise an existing signature
      // would be cleared
      this.renderSignature = true
    },
    previousPage() {
      this.$refs.wizard.previousPage()
    },
    nextPage() {
      this.$refs.wizard.nextPage()
    },
    signatureEndStroke() {
      this.signature.data = this.$refs.signature.getDataUrlSignature()
    },
    finishOrder() {
      let orderData = {
        address: this.address,
        product_total: this.selectedOfferProductsTotal,
        discount: this.selectedOfferProductsTotal * this.selectedOfferDiscount,
        discount_percentage: this.selectedOfferDiscount,
        logistics_surcharge: this.selectedOfferProductsTotalLogisticsSurcharge,
        order_total: this.selectedOfferProductsTotalAfterLogisticsSurcharge,
        wholesaler_id: this.deliveryType == 'direct' ? this.wholesalers.find(w => w.category == 'internal').id : this.wholesaler,
        products: this.selectedOfferProducts,
        signature_data: this.signature.data,
        signature_name: this.signature.name,
        delivery_date: this.deliveryDate,
        offer_id: this.selectedOffer.id,
        language: this.locale
      }

      this.orderSubmitInProcess = true

      this.$nextTick(() => {
        axios.post('/api/public-cart', orderData)
          .then(response => {
            this.$refs.offerModal.hide()
            this.$refs.orderSubmittedModal.show()
            this.orderSubmitInProcess = false

            this.wholesaler = null
            this.deliveryType = ''
            this.selectedOffer.products.forEach(p => p.quantity = 0)
          })
      })

    },
    selectAddress(address) {
      this.address.gln = address.gln
      this.address.name = address.descr1
      this.address.zip = address.zip
      this.address.city = address.city
      this.address.street = address.street + " " + address.strno

      // we don't show the search functionality, we show the address
      this.showAddress = true
    },
    enterAddressManually() {
      this.address.gln = null
      this.address.name = null
      this.address.zip = null
      this.address.city = null
      this.address.street = null
      this.address.recipient_name = null

      this.showAddress = true
    },
    resetAddress() {
      this.address.gln = null
      this.address.name = null
      this.address.zip = null
      this.address.city = null
      this.address.street = null
      this.address.recipient_name = null

      this.showAddress = false
    },
    loginSuccessful() {
      window.location.href = '/'
      this.$refs.login.hide()
    },
    loadData(){
      axios.get(`/api/public-offers?t=${new Date().getTime()}`)
        .then((response) => {
          if(response.data) {
            this.offers = response.data;
          }
        })
      axios.get(`/api/public-wholesalers?t=${new Date().getTime()}`)
        .then(response => {
          this.wholesalers = response.data
          this.internalWholesaler = { ... response.data.find(w => w.category == 'internal') }

          if (Object.keys(this.internalWholesaler) == 0) {
            this.deliveryType = 'wholesaler'
          }
        })
    },
    getCD(date) {
      if(date) {
        return Math.abs(moment(date).diff(moment(), 'milliseconds'))
      } else {
        return 0
      }
    },
    isActive(date) {
      const diff = moment(date).diff(moment(), 'milliseconds')
      return diff <= 0
    },
    offerTitle(el) {
      if (this.locale === 'fr' && el.title_fr) return el.title_fr
      if (this.locale === 'it' && el.title_it) return el.title_it
      return el.title_de
    },
    offerDescription(el) {
      if (this.locale === 'fr' && el.description_fr) return el.description_fr
      if (this.locale === 'it' && el.description_it) return el.description_it
      return el.description_de
    },
    offerSavingText(el) {
      if (this.locale === 'fr' && el.saving_percent_text_fr) return el.saving_percent_text_fr
      if (this.locale === 'it' && el.saving_percent_text_it) return el.saving_percent_text_it
      return el.saving_percent_text_de
    },
    offerImage(el) {
      if (this.locale === 'fr' && el.image_fr) return el.image_fr
      if (this.locale === 'it' && el.image_it) return el.image_it
      return el.image_de
    },
    offerDownload(el) {
      if (this.locale === 'fr' && el.download_fr) return el.download_fr
      if (this.locale === 'it' && el.download_it) return el.download_it
      return el.download_de
    },
    showReminderModal (id) {
      this.form.email = this.user ? this.user.email : ''
      this.$refs.reminder.show()
    },
    showLoginModal () {
      this.$refs.login.show()
    },
    reminder () {
      axios.post('/api/global-reminder', {
        'email': this.form.email,
        'language': this.locale
      }).then((response) => {
        console.log(response)
          }).catch((error) => {
        console.log(error)
      })
    },
    searchAddress() {
      axios.post('/api/public-addresses', {q: this.addressSearch})
        .then(response => {
          this.addressSearchResult = response.data
        })
    },
    async getAddressByGln(gln) {
      axios.post('/api/public-addresses', {gln: gln})
        .then(response => {
          this.address.gln = gln
          this.address.name = response.data.data[0].descr1
          this.address.zip = response.data.data[0].zip
          this.address.city = response.data.data[0].city
          this.address.street = response.data.data[0].street + " " + response.data.data[0].strno

          this.showAddress = true
        })
    },
    loadMore() {
      axios.post(this.addressSearchResult.next_page_url, {q: this.addressSearch})
        .then(response => {
          this.addressSearchResult.next_page_url = response.data.next_page_url
          response.data.data.forEach(item => {
            this.addressSearchResult.data.push(item)
          })
        })
    },
    fetchAddressFromGln() {
      if (this.user && this.user.gln && !this.address.name) {
        this.getAddressByGln(this.user.gln)
      }
    },
    showOfferModal(offer, offerIndex) {
      this.showLoginRegisterStep = !this.user
      this.renderSignature = false
      this.signature.data = null
      this.signature.name = null

      if ('detail' in offer) {
        this.selectedOfferIndex = offerIndex
        this.selectedOffer = offer.detail
        this.$refs.offerModal.show()
        return
      }

      axios.get(`/api/public-offers/${offer.id}?include=products,discounts`).then(response => {
        // update main offer list so that we can keep the data in case we close and re-open the order modal
        offer.detail = response.data
        // set reference to item in the list
        this.selectedOfferIndex = offerIndex
        this.selectedOffer = response.data
        this.$refs.offerModal.show()

        // fetch user using gln if there's no address yet
        this.fetchAddressFromGln()
      }).catch(err => {
        console.error(err)
      })

    }
  },
  layout: 'default',
  created() {
    moment.locale(this.locale)
  },
  mounted() {
    if(this.authenticated) {
      this.loadData()
    } else {
      this.$refs.loginBtn.click()
    }
  },
  computed: {
    ...mapGetters({
      locale: 'lang/locale',
      authenticated: 'auth/check',
      user: 'auth/user'
    }),
    cartErrors() {
      var returnArray = []
      if (this.selectedOfferProducts.length == 0) {
        returnArray.push(this.$t('offer.no_products_selected'))
      }
      if (!this.deliveryType) {
        returnArray.push(this.$t('offer.no_delivery_selected'))
      }
      if (this.deliveryType == 'wholesaler' && !this.wholesaler) {
        returnArray.push(this.$t('offer.no_wholesaler_selected'))
      }
      if (!this.address.name) {
        returnArray.push(this.$t('offer.no_name'))
      }
      if (!this.address.zip) {
        returnArray.push(this.$t('offer.no_zip'))
      }
      if (!this.address.city) {
        returnArray.push(this.$t('offer.no_city'))
      }
      if (!this.address.street) {
        returnArray.push(this.$t('offer.no_street'))
      }
      if (!this.signature.data) {
        returnArray.push(this.$t('offer.no_signature'))
      }
      if (!this.signature.name) {
        returnArray.push(this.$t('offer.no_signature_name'))
      }

      return returnArray
    },
    offersWithTitle() {
      return this.offers.filter(i => this.offerTitle(i))
    },
    selectedOfferProductsTotal() {
      return this.selectedOffer.products.reduce((acc, item) => acc + item.efp * item.quantity, 0)
    },
    selectedOfferProductsTotalAfterDiscount() {
      return this.selectedOfferProductsTotal - this.selectedOfferProductsTotal * this.selectedOfferDiscount
    },
    selectedOfferProductsTotalLogisticsSurcharge() {
      if (this.logisticsSurchage != '33_25p') {
        return 0
      }

      if (this.selectedOfferProductsTotalAfterDiscount < 500) {
        return 33.0
      }
      else {
        return this.selectedOfferProductsTotalAfterDiscount * 0.025
      }
    },
    selectedOfferProductsTotalAfterLogisticsSurcharge() {
      return this.selectedOfferProductsTotalAfterDiscount + this.selectedOfferProductsTotalLogisticsSurcharge
    },
    selectedOfferDiscount() {
      if (this.selectedOffer === null) {
        return 0
      }
      var totalQuantity = this.selectedOffer.products.reduce((acc, item) => acc + item.quantity, 0)
      var discounts = this.selectedOffer.discounts
      var selectedDiscount = 0
      discounts.forEach(discount => {
        if (discount.min_quantity <= totalQuantity) {
          selectedDiscount = Math.max(selectedDiscount, discount.discount_percentage)
        }
      })

      return selectedDiscount
    },
    selectedOfferProducts() {
      return this.selectedOffer ? this.selectedOffer.products.filter(p => p.quantity > 0) : []
    },
    logisticsSurchage() {
      if (!this.wholesaler && this.deliveryType != 'direct') {
        return null
      }

      if (this.deliveryType == 'direct') {
        var wholesaler = this.internalWholesaler
      }
      else {
        var wholesaler = this.wholesalers.find(w => w.id == this.wholesaler)
      }

      return wholesaler.logistics_surcharge
    },
    logisticsSurchageText() {
      switch (this.logisticsSurchage) {
        case 'none':
          return null
        case 'unknown':
          return this.$t('offer.unknown_logistics_surcharge')
        case '33_25p':
          return this.$t('offer.33_25p')
      }
      return null
    },
    externalWholesalers() {
      var list = this.wholesalers.filter(w => w.category == 'external')
      list.sort((a,b) => (a.wholesaler.localeCompare(b.wholesaler)))
      return list
    },
    selectedDelivery() {
      if (this.deliveryType == 'direct') {
        return this.$t('offer.direct_delivery')
      }

      if (!this.wholesalers) {
        return ''
      }

      let selectedWholesaler = this.wholesalers.find(w => w.id == this.wholesaler)
      return selectedWholesaler ? selectedWholesaler.wholesaler : ''
    }
  },
  watch: {
    locale: function (value, mutation) {
      moment.locale(value)
    },
    user(valNew, valOld) {
      if (valNew != valOld) {
        this.fetchAddressFromGln()
      }
    }
  }
}
</script>
