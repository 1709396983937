<template>
  <o-table :title="$t('offer.reminderslist')" url="/api/reminders" :enableEdit="false" :enableDelete="true" :enableNew="false" ref="tableList">
    <template slot="filter">
      <b-btn @click.prevent="xlsx()" variant="primary">
        <fa icon="file-excel"/> {{$t('core.export')}}
      </b-btn>
      <!-- <b-btn @click.prevent="sendTest()" variant="primary">
        <fa icon="paper-plane"/> {{$t('offer.send_test')}}
      </b-btn> -->
    </template>
    <template slot="head">
      <o-table-header column="Email">{{$t('core.email')}}</o-table-header>
    </template>
    <template slot="body" slot-scope="props">
      <td>{{ props.item.email }}</td>
    </template>
  </o-table>          
</template>

<script>
import axios from "axios"
import {mapGetters} from "vuex"

export default {
  data() {
    return {
      defaultFilter: [],
      dateStatus: []
    }
  },
  methods: {
    xlsx(url, config) {
      var $self = this
      axios.get(`/api/offers/export`, {responseType: 'arraybuffer'})
          .then((response) => {
            require('downloadjs')(response.data,
                $self.$i18n.t('offers.reminders') + '.xlsx',
                'application/vnd.ms-excel'
            )
          })
          .catch((error) => {
                console.log(error)
              }
          )
    },

    sendTest() {
      var email = window.prompt(this.$t('offer.recipient'))
      if (email) {
        axios.post(`/api/offers/${this.$route.params.id}/send-test`, {email: email})
        window.alert(this.$t('offer.email_sent'))
      }
    }
  },
  created() {
  },
  computed: mapGetters({
    locale: 'lang/locale',
    user: 'auth/user'
  }),
}
</script>